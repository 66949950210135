import React, { Fragment, memo, useEffect, useState,useCallback } from "react";
import { apiFilterSearchSong } from "apis";
import { Loading } from "components";
import { useNavigate } from "react-router-dom";
import {MusicPlayer} from "components";
const NewReleaseSong = () => {
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(true);
  const [NewReleaseSong, setNewReleaseSong] = useState([]);
  const [currentSong, setCurrentSong] = useState({});

  useEffect(() => {
    const FetchGetNewReleaseSong = async () => {
      try {
        const response = await apiFilterSearchSong({
          limit: 8,
          sort: "-createdAt",
          page: 1,
          status: true,
        });
        const data = response.data.data;
        setNewReleaseSong(data);
        data && setCurrentSong(data[0]);
        setisLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    FetchGetNewReleaseSong();
  }, []);
  return (
    <Fragment>
      <div className="col-lg-12">
        <div className="iq-card ">
          <div className="iq-card-header d-flex justify-content-between">
            <div className="iq-header-title">
              <h4 className="card-title font-weight-normal ">Bài hát mới</h4>
            </div>
            {/* <div
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/music");
              }}
              className="d-flex align-items-center iq-view"
            >
              <b className="mb-0 text-primary">
                <span>
                  Xem Thêm <i className="las la-angle-right"></i>
                </span>
              </b>
            </div> */}
          </div>
          <div
            style={
              `${isLoading}` === "true"
                ? { height: "20vh" }
                : { height: "auto" }
            }
            className={`iq-card-body ${isLoading && `position-relative`} `}
          >
            {isLoading ? (
              <div className="search-loading-icon">
                <Loading />
              </div>
            ) : (
              <Fragment>
                <ul className="list-unstyled iq-music-slide mb-0">
                  {NewReleaseSong?.length > 0 ? (
                    NewReleaseSong.map((item, index) => {
                      return (
                        <Fragment key={index}>
                          <MusicPlayer song={item} />
                        </Fragment>
                      );
                    })
                  ) : (
                    <li className="mb-3">
                      <div className="d-flex justify-content-center align-items-center row position-relative">
                        <div
                          style={{
                            paddingRight: "0px",
                          }}
                          className="media align-items-center "
                        >
                          <h5 className="mb-0 text-gray">No Song Found</h5>
                        </div>
                      </div>
                    </li>
                  )}
                </ul>
                <div className="d-flex justify-content-center align-items-center row">
                  <b
                    onClick={() => {
                      navigate("/music");
                    }}
                    className="mb-0 text-primary home_footer_link p-3"
                  >
                    <span>
                      Xem Thêm <i className="las la-angle-right "></i>
                    </span>
                  </b>
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default memo(NewReleaseSong);
