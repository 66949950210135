import React from "react";
export const path = {
  PUBLIC: "/",
  HOME: "/home",
  MUSIC: "music",
  MOOD_MUSIC: "/music/mood",
  GENRE_MUSIC: "/music/genre",
  INSTRUMENT_MUSIC: "/music/instrument",
  VIDEO_THEME_MUSIC: "/music/videotheme",
  DETAIL_MUSIC: "music/:slug",
  EMBED_MUSIC: "embed/music/:id",
  SIGNIN: "/signin",
  SIGNNUP: "/signup",
  FINAL_REGISTER: "/finalregister/:status",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password/:token",
  DASHBOARD: "/dashboard",
  PROFILE: "/profile",
  EDIT_PROFILE: "/edit-profile",
  CHANGE_PASSWORD: "/change-password",
  NOT_FOUND: "/not-found",
  SERVER_ERROR: "/server-error",
  SOUND_EFFECT: "/sfx",
  EMBED_SFX: "embed/sfx/:id",
  DETAIL_SFX: "/sfx/:slug",
  DETAIL_SFXCATEGORY: "/sfx/categories/:slug",
  ALBUMS: "/albums",
  EMBED_ALBUM: "embed/album/:id",
  DETAIL_ALBUM: "albums/:slug",
  COLLECTIONS: "/collections",
  MUSIC_COLLECTIONS: "/collections/c-music",
  SFX_COLLECTIONS: "/collections/c-sfx",
  EMBED_COLLECTION: "embed/collection/:id",
  DETAIL_COLLECTION: "collection/:slug",
  ARTISTS: "/artists",
  DETAIL_ARTIST: "artists/:slug",
  THEMES: "/themes",
  DETAIL_THEME: "themes/:slug",
  SUBTHEMES: "themes/:slug/:subslug",
  EMBED_SUBTHEMES: "embed/theme/:id",
  SEARCH: "/search",
  SEARCH_SONG: "/search/song",
  SEARCH_SFX: "/search/sfx",
  SEARCH_ALBUM: "/search/album",
  SEARCH_ARTIST: "/search/artist",
  SEARCH_COLLECTION: "/search/collection",
  SEARCH_SUBTHEME: "/search/subtheme",
  LICENSE: "/license",
  BLOG: "/blog",
  BLOG_HOME: "/blog/",
  BLOG_BY_CATEGORY: "/blog/:bcslug",
  BLOG_DETAIL: "/blog/post/:bslug",
  ADMIN: {
    HOME: "/admin/",
    DASHBOARD: "dashboard",
    SONG: "song",
    SONG_PAGE: "song/:page",
    ADD_SONG: "song/add",
    EDIT_SONG: "song/edit/:sid",
    SFX: "sfx",
    SFX_PAGE: "sfx/:page",
    ADD_SFX: "sfx/add",
    EDIT_SFX: "sfx/edit/:sid",
    ARTIST: "artist",
    ADD_ARTIST: "artist/add",
    EDIT_ARTIST: "artist/edit/:aid",
    GENRE: "genre",
    ADD_GENRE: "genre/add",
    MOOD: "mood",
    MOOD_PAGE: "mood/:page",
    ADD_MOOD: "mood/add",
    EDIT_MOOD: "mood/edit/:mid",
    INSTRUMENT: "instrument",
    INSTRUMENT_PAGE: "instrument/:page",
    ADD_INSTRUMENT: "instrument/add",
    EDIT_INSTRUMENT: "instrument/edit/:mid",
    THEME: "theme",
    THEME_PAGE: "theme/:page",
    ADD_THEME: "theme/add",
    EDIT_THEME: "theme/edit/:mid",
    MANAGE_THEMESUB: "theme/manage/:tid",
    SUBTHEME: "sub-theme",
    ADD_SUBTHEME: "sub-theme/add",
    EDIT_SUBTHEME: "sub-theme/edit/:stid",
    EDIT_GENRE: "genre/edit/:gid",
    ALBUM: "album",
    ALBUM_PAGE: "album/:page",
    ADD_ALBUM: "album/add",
    EDIT_ALBUM: "album/edit/:aid",
    MANAGE_DETAIL_ALBUM: "album/manage/:aid",
    COMPOSER: "composer",
    ADD_COMPOSER: "composer/add",
    COLLECTION: "collection",
    ADD_COLLECTION: "collection/add",
    SFXCATEGORY: "sfxcategory",
    SFXCATEGORY_PAGE: "sfxcategory/:page",
    ADD_SFXCATEGORY: "sfxcategory/add",
    EDIT_SFXCATEGORY: "sfxcategory/edit/:sid",
    MUSICCOLLECTION: "musiccollection",
    MUSICCOLLECTION_PAGE: "musiccollection/:page",
    ADD_MUSICCOLLECTION: "musiccollection/add",
    EDIT_MUSICCOLLECTION: "musiccollection/edit/:sid",
    SFXCOLLECTION: "sfxcollection",
    SFXCOLLECTION_PAGE: "sfxcollection/:page",
    ADD_SFXCOLLECTION: "sfxcollection/add",
    EDIT_SFXCOLLECTION: "sfxcollection/edit/:sid",
    MANAGERUSER: "manageruser",
    MANAGELICENSE: "managelicense",
    ADD_LICENSE: "managelicense/add",
    EDIT_LICENSE: "managelicense/edit/:lid",
    MANAGEBLOGCATEGORY: "manageblogCategory",
    ADD_BlOGCATEGORY: "manageblogCategory/add",
    EDIT_BlOGCATEGORY: "manageblogCategory/edit/:bcid",
    MANAGEBLOG: "manageblog",
    ADD_BlOG: "manageblog/add",
    EDIT_BlOG: "manageblog/edit/:bid",
  },
  SYSTEM: "/he-thong/",
  // EDIT_PROFILE : 'edit-profile',
  USER: {
    HOME: "/user/",
    PROFILE: "profile",
    EDIT_PROFILE: "edit-profile",
    ACCOUNT_SETTINGS: "account-settings",
    PRIVACY_SETTINGS: "privacy-settings",
  },
};
export const sorts = [
  {
    id: 1,
    text: "Newest",
    vn_text: "Mới nhất",
    value: "-createdAt",
  },
  {
    id: 2,
    text: "Oldest",
    vn_text: "Cũ nhất",
    value: "createdAt",
  },
  {
    id: 3,
    text: "Most popular",
    vn_text: "Phổ biến nhất",
    value: "-views",
  },
  {
    id: 4,
    text: "Least popular",
    vn_text: "Ít người xem nhất",
    value: "views",
  },
  {
    id: 5,
    text: "Most downloaded",
    vn_text: "Nhiều lượt tải nhất",
    value: "-downloads",
  },
  {
    id: 6,
    text: "Least downloaded",
    vn_text: "Ít lượt tải nhất",
    value: "downloads",
  },
  {
    id: 7,
    text: "Alphabetical (A-Z)",
    vn_text: "A-Z",
    value: "title",
  },
  {
    id: 8,
    text: "Alphabetical (Z-A)",
    vn_text: "Z-A",
    value: "-title",
  },
];
export const duration = [
  { min: 0, max: 0, label: "Thời lượng bất kỳ" },
  { min: 0, max: 30, label: "0-30 giây" },
  { min: 30, max: 120, label: "30 giây - 2 phút" },
  { min: 120, max: 300, label: "2-5 phút" },
  { min: 300, max: 600, label: "5-10 phút" },
  { min: 600, max: 0, label: "Trên 10 phút" },
];

export const contentID = [
  {
    id: 1,
    vn_text: "",
    eng_text: "",
  },
  {
    id: 2,
    vn_text:
      "Content ID is a digital fingerprinting system developed by Google.It can be used by content creators to easily identify and manage copyrighted content on YouTube",
    eng_text:
      "Content ID is a digital fingerprinting system developed by Google.It can be used by content creators to easily identify and manage copyrighted content on YouTube",
  },
];

export const adminSidebar = [
  {
    id: 1,
    text: "Dashboard",
    vn_text: "Bảng điều khiển",
    icon: <i className="las la-home"></i>,
    link: path.ADMIN.DASHBOARD,
  },
  {
    id: 2,
    text: "Songs",
    vn_text: "Bài hát",
    icon: <i className="las la-play-circle"></i>,
    link: path.ADMIN.SONG,
  },
  {
    id: 3,
    text: "Genres",
    vn_text: "Thể loại",
    icon: <i className="ri-function-line"></i>,
    link: path.ADMIN.GENRE,
  },
  {
    id: 4,
    text: "Moods",
    vn_text: "Tâm trạng",
    icon: <i className="ri-drop-fill"></i>,
    link: path.ADMIN.MOOD,
  },
  {
    id: 5,
    text: "Instruments",
    vn_text: "Nhạc cụ",
    icon: <i className="ri-netease-cloud-music-line"></i>,
    link: path.ADMIN.INSTRUMENT,
  },
  {
    id: 6,
    text: "Manage Themes",
    vn_text: "Quản lý chủ đề",
    icon: <i className="ri-vidicon-fill iq-arrow-left" />,
    link: path.ADMIN.THEME,
    submenus: [
      {
        id: 1,
        text: "Themes",
        vn_text: "Chủ đề",
        icon: <i className="ri-dv-fill"></i>,
        link: path.ADMIN.THEME,
      },
      {
        id: 2,
        text: "Subthemes",
        vn_text: "Chủ đề con",
        icon: <i className="ri-movie-fill"></i>,
        link: path.ADMIN.SUBTHEME,
      },
    ],
  },
  {
    id: 7,
    text: "Albums",
    vn_text: "Albums",
    icon: <i className="ri-disc-fill"></i>,
    link: path.ADMIN.ALBUM,
  },
  {
    id: 8,
    text: "Artists",
    vn_text: "Nghệ sĩ",
    icon: <i className="las la-user"></i>,
    link: path.ADMIN.ARTIST,
  },
  {
    id: 9,
    text: "Sound Effects",
    vn_text: "Hiệu ứng âm thanh",
    icon: <i className="las la-microphone-alt iq-arrow-left"></i>,
    link: path.ADMIN.SFX,
    submenus: [
      {
        id: 1,
        text: "SFX Categories",
        vn_text: "Danh mục hiệu ứng âm thanh",
        icon: <i className="las la-th-list"></i>,
        link: path.ADMIN.SFXCATEGORY,
      },
      {
        id: 2,
        text: "Sound Effects",
        vn_text: "Hiệu ứng âm thanh",
        icon: <i className="las la-microphone-alt"></i>,
        link: path.ADMIN.SFX,
      },
    ],
  },
  {
    id: 10,
    text: "Collections",
    vn_text: "Bộ sưu tập",
    icon: <i className="lab la-elementor iq-arrow-left"></i>,
    link: path.ADMIN.COLLECTION,
    submenus: [
      {
        id: 1,
        text: "Music Collections",
        vn_text: "Bộ sưu tập âm nhạc",
        icon: <i className="ri-play-circle-line"></i>,
        link: path.ADMIN.MUSICCOLLECTION,
      },
      {
        id: 2,
        text: "Sound Effects Collections",
        vn_text: "Bộ sưu tập hiệu ứng âm thanh",
        icon: <i className="las la-microphone-alt"></i>,
        link: path.ADMIN.SFXCOLLECTION,
      },
    ],
  },
  {
    id: 11,
    text: "License",
    vn_text: "Giấy phép",
    icon: <i className="las la-info-circle"></i>,
    link: path.ADMIN.MANAGELICENSE,
    submenus: [
      {
        id: 1,
        text: "Create License",
        vn_text: "Tạo giấy phép",
        icon: <i className="las la-plus-circle"></i>,
        link: path.ADMIN.ADD_LICENSE,
      },
      {
        id: 2,
        text: "Manage License",
        vn_text: "Quản lý giấy phép",
        icon: <i className="las la-info-circle"></i>,
        link: path.ADMIN.MANAGELICENSE,
      },
    ],
  },
  {
    id: 12,
    text: "Manage Blog",
    vn_text: "Giấy phép",
    icon: <i className="las la-blog"></i>,
    link: path.ADMIN.MANAGEBLOG,
    submenus: [
      {
        id: 1,
        text: "BlogCategory",
        vn_text: "Quản lý danh mục blog",
        icon: <i className="lab la-blogger"></i>,
        link: path.ADMIN.MANAGEBLOGCATEGORY,
      },
      {
        id: 2,
        text: "Blog",
        vn_text: "Quản lý blog",
        icon: <i className="las la-blog"></i>,
        link: path.ADMIN.MANAGEBLOG,
      },
    ],
  },
  {
    id: 13,
    text: <span className="text-danger">***Manage User</span>,
    vn_text: "Quản lý người dùng",
    icon: <i className="las la-users"></i>,
    link: path.ADMIN.MANAGERUSER,
  },
];
export const roles = [
  {
    code: 6699,
    value: "Admin",
  },
  {
    code: 1346,
    value: "User",
  },
];
export const blockStatus = [
  {
    code: true,
    value: "Blocked",
  },
  {
    code: false,
    value: "Active",
  },
];
