const actionTypes = {
    SIGNIN_SUCCESS: 'SIGNIN_SUCCESS',
    SIGNIN_FAIL: 'SIGNIN_FAIL',
    SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
    SIGNUP_FAIL: 'SIGNUP_FAIL',
    SIGNOUT: 'SIGNOUT',
    SIGNOUT_SUCCESS : 'SIGNOUT_SUCCESS',
    SIGNOUT_FAIL: 'SIGNOUT_FAIL',
    GET_SONG : 'GET_SONG',
    GET_NEW_RELEASES : 'GET_NEW_RELEASES',
    GET_NEW_ALBUMS : 'GET_NEW_ALBUMS',
    GET_CURRENT_USER: 'GET_CURRENT_USER',
    GET_CURRENT_USER_FAIL: 'GET_CURRENT_USER_FAIL',
    FORGOT_PASSWORD_FAIL : 'FORGOT_PASSWORD_FAIL',
    FORGOT_PASSWORD_SUCCESS : 'FORGOT_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAIL : 'RESET_PASSWORD_FAIL',
    RESET_PASSWORD_SUCCESS : 'RESET_PASSWORD_SUCCESS',
    CLEAR_STATE : 'CLEAR_STATE',
    CLEAR_MSG : 'CLEAR_MSG',
    SET_CURRENT_SONG_ID : 'SET_CURRENT_SONG_ID',
    SET_IS_PLAYING : 'SET_IS_PLAYING',
    SET_IS_SHOWMODAL : 'SET_IS_SHOWMODAL',
    SET_MODAL_DATA : 'SET_MODAL_DATA',
    GET_SFXCATEGORIES : 'GET_SFXCATEGORIES',
    GET_ALL_SONGS : 'GET_ALL_SONGS',
    GET_ALL_ALBUMS : 'GET_ALL_ALBUMS',
    GET_LIST_ALBUMS : 'GET_LIST_ALBUMS',
    GET_ALL_ARTISTS : 'GET_ALL_ARTISTS',
    GET_ALL_SFX : 'GET_ALL_SFX',
    GET_ALL_SFXCATEGORY : 'GET_ALL_SFXCATEGORY',
    GET_ALL_MUSICCOLLECTIONS_HOME : 'GET_ALL_MUSICCOLLECTIONS_HOME',
    GET_ALL_SFXCOLLECTIONS_HOME : 'GET_ALL_SFXCOLLECTIONS_HOME',
    GET_NEW_RELEASES_COLLECTIONS : 'GET_NEW_RELEASES_COLLECTIONS',
    SEARCH : 'SEARCH',
    SEARCH_MUSIC_BY_TITLE : 'SEARCH_MUSIC_BY_TITLE',
    SEARCH_SFX_BY_TITLE : 'SEARCH_SFX_BY_TITLE',
    SEARCH_ARTIST_BY_NAME : 'SEARCH_ARTIST_BY_NAME',
    SEARCH_ALBUM_BY_TITLE : 'SEARCH_ALBUM_BY_TITLE',
    SEARCH_COLLECTION_BY_TITLE : 'SEARCH_COLLECTION_BY_TITLE',
    SEARCH_CURRENT_LIST_SONGS : 'SEARCH_CURRENT_LIST_SONGS',
    SET_ALBUM : 'SET_ALBUM',
    PLAYLIST : 'PLAYLIST',
    DOWNLOAD_MANAGER : 'DOWNLOAD_MANAGER',

}
export default actionTypes;

// 